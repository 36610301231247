define('diesel/models/billing-detail', ['exports', 'ember', 'ember-data', 'diesel/models/base', 'diesel/utils/stripe'], function (exports, _ember, _emberData, _dieselModelsBase, _dieselUtilsStripe) {
  exports['default'] = _dieselModelsBase['default'].extend({
    billingCycles: _emberData['default'].hasMany('billing-cycles', { async: true }),
    billingContacts: _emberData['default'].hasMany('billing-contacts', { async: true }),
    contracts: _emberData['default'].hasMany('contracts', { async: true }),
    credits: _emberData['default'].hasMany('credits', { async: true }),
    stripeSources: _emberData['default'].hasMany('stripe-source', { async: true }),
    externalPaymentSources: _emberData['default'].hasMany('external-payment-source', { async: true }),
    trials: _emberData['default'].hasMany('trials', { async: true }),
    activePlan: _emberData['default'].belongsTo('plan', { async: true }),

    organizationDetailsJson: _emberData['default'].attr('humps'),

    // NOTE: We set paymentMethod as a string, so that we can send its href when
    // we save it.
    paymentMethod: _emberData['default'].attr('polymorphic-write-only'),

    invoicedExternally: _emberData['default'].attr('boolean'),
    externalInvoicePortalUrl: _emberData['default'].attr('string'),

    currentTrial: _ember['default'].computed('trials.[]', function () {
      var promise = this.get('trials').then(function (trial) {
        return trial.filter(function (t) {
          return t.get('active');
        })[0];
      });
      return _emberData['default'].PromiseObject.create({ promise: promise });
    }),

    hasNoBillingAddress: _ember['default'].computed('currentTrial', 'organizationDetailsJson.billingAddress', function () {
      var _this = this;

      var promise = this.get('currentTrial').then(function (trial) {
        return !trial && !_this.get('organizationDetailsJson.billingAddress.streetOne');
      });

      return _emberData['default'].PromiseObject.create({ promise: promise });
    }),

    hasPaymentMethod: _ember['default'].computed('links.payment_method', function () {
      return !!this.get('links.payment_method');
    }),

    hasNoPaymentMethod: _ember['default'].computed.not('hasPaymentMethod'),

    paymentMethods: _ember['default'].computed('stripeSources.[]', 'externalPaymentSources.[]', 'trials.[]', function () {
      var promise = _ember['default'].RSVP.hash(this.getProperties('stripeSources', 'externalPaymentSources', 'trials')).then(function (groups) {
        var ret = _ember['default'].A([]);
        Object.keys(groups).forEach(function (k) {
          return groups[k].forEach(function (s) {
            return ret.pushObject(s);
          });
        });
        return ret;
      });

      return _emberData['default'].PromiseArray.create({ promise: promise });
    }),

    hasNoManualPaymentMethod: _ember['default'].computed.empty('paymentMethodsForManualPayment'),
    activeTrial: _ember['default'].computed.bool('currentTrial.active'),
    activeTrialAndHasNoManualPaymentMethod: _ember['default'].computed.and('currentTrial.active', 'hasNoManualPaymentMethod'),

    paymentMethodsForManualPayment: _ember['default'].computed('stripeSources.[]', function () {
      var promise = this.get('stripeSources').then(function (stripeSources) {
        return stripeSources.filter(function (s) {
          return s.get('active');
        });
      });
      return _emberData['default'].PromiseArray.create({ promise: promise });
    }),

    organization: _ember['default'].computed('id', function () {
      var promise = this.store.find('organization', this.get('id'));
      return _emberData['default'].PromiseObject.create({ promise: promise });
    }),

    addDefaultCreditCard: function addDefaultCreditCard(creditCard) {
      var _this2 = this;

      var billingDetail = this;

      return (0, _dieselUtilsStripe.createStripeToken)(_dieselUtilsStripe.CreditCardSpec, creditCard).then(function (stripeResponse) {
        return _this2.store.createRecord('stripe-source', {
          billingDetail: billingDetail,
          stripeTokenId: stripeResponse.id
        }).save();
      }).then(function (stripeSource) {
        billingDetail.set('paymentMethod', stripeSource);
        return billingDetail.save();
      });
    },

    addBankAccount: function addBankAccount(bankAccount) {
      var _this3 = this;

      var billingDetail = this;

      return (0, _dieselUtilsStripe.createStripeToken)(_dieselUtilsStripe.BankAccountSpec, bankAccount).then(function (stripeResponse) {
        return _this3.store.createRecord('stripe-source', {
          billingDetail: billingDetail,
          stripeTokenId: stripeResponse.id
        }).save();
      });
    }
  });
});