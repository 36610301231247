define('diesel/organization/admin/billing/invoices/index/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    actions: {
      showCurrent: function showCurrent() {
        // Ember doesn't like it when you navigate to the same URL, so let's trick it
        var currentUrl = new URL(window.location.href);
        currentUrl.search = '';
        window.location.assign(currentUrl);
      }
    },
    historicalBillingIterations: _ember['default'].computed('model.billingIterations.@each.hasInvoice', function () {
      return this.get('model.billingIterations').filterBy('hasInvoice', true);
    }),
    projectedBillingIterations: _ember['default'].computed('model.billingIterations.@each.hasInvoice', function () {
      if (this.get('model.invoicedExternally')) return null;
      return this.get('model.billingIterations').filterBy('hasInvoice', false);
    })
  });
});