define('diesel/organization/admin/billing/invoices/controller', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        actions: {
            // Ember doesn't like it when you navigate to the same URL, so let's trick it
            showHistorical: function showHistorical() {
                var currentUrl = new URL(window.location.href);
                currentUrl.searchParams.set('pastInvoices', "1");
                window.location.assign(currentUrl);
            }
        }
    });
});