define('diesel/organization/admin/billing/invoices/route', ['exports', 'ember'], function (exports, _ember) {

  var BillingRoute = 'organization.admin.billing';

  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      // TODO: This does not work if billingDetail is nil. We should handle
      // those.
      var billingDetail = this.modelFor(BillingRoute);
      var urlParams = new URLSearchParams(window.location.search);
      var pastInvoices = urlParams.get('pastInvoices');

      // Iterate over billing cycles and get associated billing iterations
      // ultimately resolve a single array of all billing iterations
      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        var billingIterations = [];
        billingDetail.get('billingCycles').then(function (cycles) {
          billingIterations = billingIterations.concat(cycles.map(function (c) {
            return c.get('billingIterations');
          }));
          _ember['default'].RSVP.all(billingIterations).then(function (cycleBillingIterations) {
            var result = [];
            cycleBillingIterations.forEach(function (c) {
              return c.forEach(function (ci) {
                return result.push(ci);
              });
            }); // Flatten
            resolve({
              billingIterations: result,
              pastInvoices: pastInvoices,
              activeTrial: billingDetail.get('activeTrial'),
              invoicedExternally: billingDetail.get('invoicedExternally'),
              externalInvoicePortalUrl: billingDetail.get('externalInvoicePortalUrl')
            });
          });
        })['catch'](reject);
      });
    }
  });
});